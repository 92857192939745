import Request from  '@/request/Request'
import Encrypt from '@/encrypt/Encrypt'




const Service = {
    login: (username, password) => {
        return Request.post("/api/smart/access/agent", {username: username, password: password});
    },
    account: (id) =>{
        return Request.post('/api/smart/agent/detail', {key: id});
    },
    authorities: (id) =>{
        return Request.post('/api/smart/account/authorities', {key: id});
    },
    getConfig: ()=> Request.post('/api/smart/config/list', {}),
    updateConfig: (d)=> Request.post('/api/smart/config/update', {payload: d}),
    cancelOrder: (orderId) => {
        return Request.post('/api/smart/order/cancel', { orderId: orderId});
    },
    coverOrder: (orderId) => {
        return Request.post('/api/smart/order/cover', {orderId: orderId});
    },
    cancelLockOrder: (id) => {
        return Request.post('/api/smart/order/cancelLock', {id: id});
    },
    lockOrder: (id) => {
        return Request.post('/api/smart/order/lock', {id: id});
    }
}

export default Service