import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'index',
		component: () => import('@/views/Login.vue')
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/Login.vue')
	},
	{
		path: '/home',
		name: 'home',
		component: () => import('@/views/Home.vue'),
		meta: {
			title: "管理系统-主页"
		},
		redirect: '/home/welcome',
		children: [{
				path: '/home/welcome',
				name: 'home_welcome',
				component: () => import('@/views/Welcome.vue'),
				meta: {
					title: '后台管理系统-欢迎'
				}
			},
			{
				path: '/admin/account',
				name: 'admin_account',
				component: () => import('@/views/admin/Account.vue'),
				meta: {
					title: '后台管理系统-账号管理'
				}
			},
			{
				path: '/admin/role',
				name: 'admin_ole',
				component: () => import('@/views/admin/Role.vue'),
				meta: {
					title: '后台管理系统-角色管理'
				}
			},
			// { path: '/admin/config', name: 'admin_config', component: () => import('@/views/admin/Config.vue'), meta: { title: '后台管理系统-系统配置' } },
			{
				path: '/product/category',
				name: 'product_category',
				component: () => import('@/views/product/Category.vue'),
				meta: {
					title: '产品管理-分类管理'
				}
			},
			{
				path: '/product/product',
				name: 'product_product',
				component: () => import('@/views/product/Product.vue'),
				meta: {
					title: '产品管理-产品'
				}
			},
			{
				path: '/product/product_new',
				name: 'product_product_new',
				component: () => import('@/views/product/ProductNew.vue'),
				meta: {
					title: '产品管理-新股/配售/大宗'
				}
			},
			{
				path: '/product/product_chatgpt',
				name: 'product_product_chatgpt',
				component: () => import('@/views/product/ProductChatgpt.vue'),
				meta: {
					title: '产品管理-AI产品'
				}
			},

			{
				path: '/user/list',
				name: 'user_list',
				component: () => import('@/views/user/Users.vue'),
				meta: {
					title: '用户清单'
				}
			},
			{
				path: '/user/sms_log',
				name: 'sms_log',
				component: () => import('@/views/user/SmsLog.vue'),
				meta: {
					title: '短信记录'
				}
			},

			{
				path: '/order/charge',
				name: 'order_charge',
				component: () => import('@/views/order/Charge.vue'),
				meta: {
					title: '用户入金记录'
				}
			},
			{
				path: '/order/withdraw',
				name: 'order_withdraw',
				component: () => import('@/views/order/Withdraw.vue'),
				meta: {
					title: '用户出金记录'
				}
			},
			{
				path: '/order/created',
				name: 'order_created',
				component: () => import('@/views/order/Created.vue'),
				meta: {
					title: '挂单记录'
				}
			},
			{
				path: '/order/build',
				name: 'order_build',
				component: () => import('@/views/order/Position.vue'),
				meta: {
					title: '持仓清单'
				}
			},
			{
				path: '/order/history',
				name: 'order_history',
				component: () => import('@/views/order/History.vue'),
				meta: {
					title: '历史订单'
				}
			},
			{
				path: '/order/xingu',
				name: 'order_xingu',
				component: () => import('@/views/order/XinGu.vue'),
				meta: {
					title: '新股订单'
				}
			},
			{
				path: '/order/peishou',
				name: 'order_peishou',
				component: () => import('@/views/order/PeiShou.vue'),
				meta: {
					title: '配售订单'
				}
			},
			{
				path: '/order/dazong',
				name: 'order_dazong',
				component: () => import('@/views/order/DaZong.vue'),
				meta: {
					title: '大宗交易订单'
				}
			},
			{
				path: '/order/ai_stock',
				name: 'order_ai_stock',
				component: () => import('@/views/order/AiStock.vue'),
				meta: {
					title: 'AE选股订单'
				}
			},
			{
				path: '/order/provider',
				name: 'order_provider',
				component: () => import('@/views/order/Provider.vue'),
				meta: {
					title: '创建订单'
				}
			},

			{
				path: '/wallet/income',
				name: 'wallet_income',
				component: () => import('@/views/wallet/Income.vue'),
				meta: {
					title: '收款通路清单'
				}
			},
			{
				path: '/wallet/currency',
				name: 'wallet_currency',
				component: () => import('@/views/wallet/currency.vue'),
				meta: {
					title: '收款通路清单'
				}
			},
			{
				path: '/wallet/currency_channel',
				name: 'wallet_currency_channel',
				component: () => import('@/views/wallet/currency_channel.vue'),
				meta: {
					title: '收款通路清单'
				}
			},
			{
				path: '/withdraw/bank',
				name: 'withdraw_bank',
				component: () => import('@/views/wallet/bank.vue'),
				meta: {
					title: '银行列表'
				}
			},
			{
				path: '/wallet/outcome',
				name: 'wallet_outcome',
				component: () => import('@/views/wallet/Outcome.vue'),
				meta: {
					title: '支付通路清单'
				}
			},
			{
				path: '/wallet/outcome_currency',
				name: 'wallet_outcome_currency',
				component: () => import('@/views/wallet/outcome_currency.vue'),
				meta: {
					title: '支付通路清单'
				}
			},

			{
				path: '/data/config',
				name: 'data_config',
				component: () => import('@/views/data/Config.vue'),
				meta: {
					title: '点差调节器'
				}
			},

			{
				path: '/setting/notice',
				name: 'setting_notice',
				component: () => import('@/views/setting/Notice.vue'),
				meta: {
					title: '消息通知'
				}
			},
			{
				path: '/setting/alert',
				name: 'setting_alert',
				component: () => import('@/views/setting/Alert.vue'),
				meta: {
					title: '弹窗消息'
				}
			},
			{
				path: '/setting/about',
				name: 'setting_about',
				component: () => import('@/views/setting/About.vue'),
				meta: {
					title: '关于我们'
				}
			},
			{
				path: '/setting/announce',
				name: 'setting_announce',
				component: () => import('@/views/setting/Announce.vue'),
				meta: {
					title: '免责声明'
				}
			},
			{
				path: '/setting/customer',
				name: 'setting_customer',
				component: () => import('@/views/setting/Customer.vue'),
				meta: {
					title: '客服'
				}
			},
			{
				path: '/setting/news',
				name: 'setting_news',
				component: () => import('@/views/setting/News.vue'),
				meta: {
					title: '客服'
				}
			},

			{
				path: '/agent/list',
				name: 'agent_list',
				component: () => import('@/views/agent/Agent.vue'),
				meta: {
					title: '代理商清单'
				}
			},
			{
				path: '/agent/user',
				name: 'agent_user',
				component: () => import('@/views/agent/User.vue'),
				meta: {
					title: '代理用户清单'
				}
			},

		],
	},
	{
		path: '*',
		name: '404',
		component: () => import('@/views/404.vue'),
		meta: {
			title: 'Not found'
		}
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router