const Category = {
	FOLDER: {
		code: 'FOLDER',
		name: '菜单组',
	},
	GROUP: {
		code: "GROUP",
		name: "分组",
	},
	MENU: {
		code: 'MENU',
		name: '菜单'
	},
	BUTTON: {
		code: 'BUTTON',
		name: '按钮'
	}
}

const MenuCode = {
	admin: "admin",
	admin_account: "admin_account",
	admin_role: "admin_role",
	admin_config: "admin_config",

	product: 'product',
	product_category: 'product_category',
	product_product: 'product_product',
	product_product_new: 'product_product_new',
	product_product_chatgpt: 'product_product_chatgpt',

	user: "user",
	user_list: "user_list",
	sms_log: "sms_log",

	order: 'order',
	order_charge: "order_charge",
	order_withdraw: "order_withdraw",
	order_created: "order_created",
	order_build: "order_build",
	order_history: "order_history",
	order_xingu: "order_xingu",
	order_peishou: "order_peishou",
	order_dazong: "order_dazong",
	order_ai_stock: "order_ai_stock",
	order_create_order: "order_create_order",

	wallet: "wallet",
	wallet_income: "wallet_income",
	wallet_income_currency: "wallet_income_currency",
	wallet_income_channel: "wallet_income_channel",

	withdraw: "withdraw",
	withdraw_outcome: "wallet_outcome",
	withdraw_outcome_currency: "withdraw_outcome_currency",
	withdraw_bank: "withdraw_bank",

	setting: "setting",
	setting_notice: "setting_notice",
	setting_alert: "setting_alert",
	setting_about: "setting_about",
	setting_announce: "setting_announce",
	setting_customer: "setting_customer",
	setting_news: "setting_news",

	data: "data",
	data_config: "data_config",

	agent: "agent",
	agent_list: "agent_list",
	agent_user: 'agent_user',
}

const Menus = [
	// {
	// 	name: "许可证管理",
	// 	route: "",
	// 	category: Category.FOLDER.code,
	// 	icon: "el-icon-setting",
	// 	code: MenuCode.admin,
	// 	i18n: 'menu.admin',
	// 	children: [{
	// 			name: "管理员清单",
	// 			route: "/admin/account",
	// 			category: Category.MENU.code,
	// 			icon: "el-icon-user",
	// 			code: MenuCode.admin_account,
	// 			i18n: 'menu.admin_account',
	// 			children: []
	// 		},
	// 		{
	// 			name: "系统角色管理",
	// 			route: "/admin/role",
	// 			category: Category.MENU.code,
	// 			icon: "el-icon-view",
	// 			code: MenuCode.admin_role,
	// 			i18n: 'menu.admin_role',
	// 			children: []
	// 		},
	// 		// {
	// 		//     name: "系统参数配置",
	// 		//     route: "/admin/config",
	// 		//     category: Category.MENU.code,
	// 		//     icon: "el-icon-setting",
	// 		//     code: MenuCode.admin_config,
	// 		//     i18n: 'menu.admin_config',
	// 		//     children: [
	// 		//     ]
	// 		// }
	// 	]
	// },
	// {
	// 	name: "产品",
	// 	route: "",
	// 	category: Category.FOLDER.code,
	// 	icon: "el-icon-s-goods",
	// 	code: MenuCode.product,
	// 	i18n: 'menu.product',
	// 	children: [{
	// 			name: "产品分类",
	// 			route: "/product/category",
	// 			category: Category.MENU.code,
	// 			icon: "el-icon-s-operation",
	// 			code: MenuCode.product_category,
	// 			i18n: 'menu.product_category',
	// 			children: []
	// 		},
	// 		{
	// 			name: "产品",
	// 			route: "/product/product",
	// 			category: Category.MENU.code,
	// 			icon: "el-icon-goods",
	// 			code: MenuCode.product_product,
	// 			i18n: 'menu.product_product',
	// 			children: []
	// 		},
	// 		{
	// 			name: "新股/配售/大宗",
	// 			route: "/product/product_new",
	// 			category: Category.MENU.code,
	// 			icon: "el-icon-goods",
	// 			code: MenuCode.product_product_new,
	// 			i18n: 'menu.product_product_new',
	// 			children: []
	// 		},
	// 		{
	// 			name: "AI产品",
	// 			route: "/product/product_chatgpt",
	// 			category: Category.MENU.code,
	// 			icon: "el-icon-goods",
	// 			code: MenuCode.product_product_chatgpt,
	// 			i18n: 'menu.product_product_chatgpt',
	// 			children: []
	// 		},
	// 	]
	// },
	{
		name: "用户",
		route: "",
		category: Category.FOLDER.code,
		icon: "iconfont icon-user",
		code: MenuCode.user,
		i18n: 'menu.user',
		children: [{
				name: "用户列表",
				route: "/user/list",
				category: Category.MENU.code,
				icon: "iconfont icon-user",
				code: MenuCode.user_list,
				i18n: 'menu.user_list',
				children: [{
						name: "添加",
						category: Category.BUTTON.code,
						code: 'user_list_add'
					},
					{
						name: "用户审核",
						category: Category.BUTTON.code,
						code: 'user_list_audit'
					},
					{
						name: "一字板额度",
						category: Category.BUTTON.code,
						code: 'user_list_yzbed'
					},
					{
						name: "AI额度",
						category: Category.BUTTON.code,
						code: 'user_list_aied'
					},
					{
						name: "大宗额度",
						category: Category.BUTTON.code,
						code: 'user_list_dzed'
					},
					{
						name: "用户详情",
						category: Category.BUTTON.code,
						code: 'user_list_detail'
					},
					{
						name: "GOOGLE验证码重置",
						category: Category.BUTTON.code,
						code: 'user_list_ggcode'
					},
					{
						name: "实名认证审核",
						category: Category.BUTTON.code,
						code: 'user_list_credit'
					},
					{
						name: "账号机构权限",
						category: Category.BUTTON.code,
						code: 'user_list_orgni'
					},
					// { 	name: "资金调整", category: Category.BUTTON.code, code: 'user_list_balance'},
					{
						name: "资金流水",
						category: Category.BUTTON.code,
						code: 'user_list_record'
					},
					{
						name: "银行卡信息",
						category: Category.BUTTON.code,
						code: 'user_list_card'
					},
					{
						name: "收藏列表",
						category: Category.BUTTON.code,
						code: 'user_list_collect'
					},
				]
			}, {
				name: "短信记录",
				route: "/user/sms_log",
				category: Category.MENU.code,
				icon: "iconfont icon-user",
				code: MenuCode.sms_log,
				i18n: 'menu.sms_log',
				children: []
			},

		]
	},
	{
		name: "订单",
		route: "",
		category: Category.FOLDER.code,
		icon: "iconfont icon-wodedingdan",
		code: MenuCode.order,
		i18n: 'menu.order',
		children: [{
				name: "用户入金订单",
				route: "/order/charge",
				category: Category.MENU.code,
				icon: "iconfont icon-3",
				code: MenuCode.order_charge,
				i18n: 'menu.order_charge',
				children: [
					// { 	name: "审核", category: Category.BUTTON.code, code: 'order_charge_audit'},
				]
			},
			{
				name: "用户出金订单",
				route: "/order/withdraw",
				category: Category.MENU.code,
				icon: "iconfont icon-tixian",
				code: MenuCode.order_withdraw,
				i18n: 'menu.order_withdraw',
				children: [
					// { 	name: "审核", category: Category.BUTTON.code, code: 'order_withdraw_audit'},
				]
			},
			{
				name: "用户挂单记录",
				route: "/order/created",
				category: Category.MENU.code,
				icon: "iconfont icon-tixian",
				code: MenuCode.order_created,
				i18n: 'menu.order_created',
				children: []
			},
			{
				name: "用户持仓清单",
				route: "/order/build",
				category: Category.MENU.code,
				icon: "iconfont icon-tixian",
				code: MenuCode.order_build,
				i18n: 'menu.order_build',
				children: [{
						name: "平仓",
						category: Category.BUTTON.code,
						code: 'position_cover'
					},
					{
						name: "锁仓",
						category: Category.BUTTON.code,
						code: 'position_lock'
					},
					{
						name: "取消锁仓",
						category: Category.BUTTON.code,
						code: 'position_cancel_lock'
					},
					{
						name: "锁仓天数",
						category: Category.BUTTON.code,
						code: 'position_lock_days'
					},

				]
			},
			{
				name: "历史订单清单",
				route: "/order/history",
				category: Category.MENU.code,
				icon: "iconfont icon-tixian",
				code: MenuCode.order_history,
				i18n: 'menu.order_history',
				children: []
			},
			{
				name: "新股订单记录",
				route: "/order/xingu",
				category: Category.MENU.code,
				icon: "iconfont icon-tixian",
				code: MenuCode.order_xingu,
				i18n: 'menu.order_xingu',
				children: [{
						name: "公布中签",
						category: Category.BUTTON.code,
						code: 'order_xingu_pub'
					},
					{
						name: "转持仓",
						category: Category.BUTTON.code,
						code: 'order_xingu_build'
					},
				]
			},
			{
				name: "配售订单记录",
				route: "/order/peishou",
				category: Category.MENU.code,
				icon: "iconfont icon-tixian",
				code: MenuCode.order_peishou,
				i18n: 'menu.order_peishou',
				children: [{
						name: "公布中签",
						category: Category.BUTTON.code,
						code: 'order_pershou_pub'
					},
					{
						name: "转持仓",
						category: Category.BUTTON.code,
						code: 'order_pershou_build'
					},
				]
			},
			{
				name: "大宗交易订单记录",
				route: "/order/dazong",
				category: Category.MENU.code,
				icon: "iconfont icon-tixian",
				code: MenuCode.order_dazong,
				i18n: 'menu.order_dazong',
				children: [{
					name: "分配金额",
					category: Category.BUTTON.code,
					code: 'order_dz_set_amount'
				}, ]
			},
			{
				name: "AE选股订单",
				route: "/order/ai_stock",
				category: Category.MENU.code,
				icon: "iconfont icon-tixian",
				code: MenuCode.order_ai_stock,
				i18n: 'menu.order_ai_stock',
				children: [{
					name: "分配金额",
					category: Category.BUTTON.code,
					code: 'order_ai_set_amount'
				}, {
					name: "持仓",
					category: Category.BUTTON.code,
					code: 'order_aistock_build'
				}, ]
			},
			{
				name: "创建订单",
				route: "/order/Provider",
				category: Category.MENU.code,
				icon: "iconfont icon-tixian",
				code: MenuCode.order_create_order,
				i18n: 'menu.order_create_order',
				children: [
				]
			},
		]
	},
	// {
	// 	name: "充值配置",
	// 	route: "",
	// 	category: Category.FOLDER.code,
	// 	icon: "fa fa-diamond",
	// 	code: MenuCode.wallet,
	// 	i18n: 'menu.wallet',
	// 	children: [

	// 		{
	// 			name: "数字货币充币设置",
	// 			route: "/wallet/income",
	// 			category: Category.MENU.code,
	// 			icon: "fa fa-chevron-right",
	// 			code: MenuCode.wallet_income,
	// 			i18n: 'menu.wallet_income',
	// 			children: []
	// 		}, {
	// 			name: "电汇充值货币设置",
	// 			route: "/wallet/currency",
	// 			category: Category.MENU.code,
	// 			icon: "fa fa-chevron-right",
	// 			code: MenuCode.wallet_income_currency,
	// 			i18n: 'menu.wallet_income',
	// 			children: []
	// 		}, {
	// 			name: "电汇充值账号设置",
	// 			route: "/wallet/currency_channel",
	// 			category: Category.MENU.code,
	// 			icon: "fa fa-chevron-right",
	// 			code: MenuCode.wallet_income_channel,
	// 			i18n: 'menu.wallet_income',
	// 			children: []
	// 		}
	// 	]
	// },
	// {
	// 	name: "提现配置",
	// 	route: "",
	// 	category: Category.FOLDER.code,
	// 	icon: "fa fa-diamond",
	// 	code: MenuCode.withdraw,
	// 	i18n: 'menu.wallet',
	// 	children: [{
	// 		name: "银行列表",
	// 		route: "/withdraw/bank",
	// 		category: Category.MENU.code,
	// 		icon: "fa fa-chevron-left",
	// 		code: MenuCode.withdraw_bank,
	// 		i18n: 'menu.wallet_outcome',
	// 		children: []
	// 	}, {
	// 		name: "数字货币提现配置",
	// 		route: "/wallet/outcome",
	// 		category: Category.MENU.code,
	// 		icon: "fa fa-chevron-left",
	// 		code: MenuCode.withdraw_outcome,
	// 		i18n: 'menu.wallet_outcome',
	// 		children: []
	// 	}, {
	// 		name: "法币提现通道",
	// 		route: "/wallet/outcome_currency",
	// 		category: Category.MENU.code,
	// 		icon: "fa fa-chevron-left",
	// 		code: MenuCode.withdraw_outcome_currency,
	// 		i18n: 'menu.wallet_outcome',
	// 		children: []
	// 	}, ]
	// },
	// {
	// 	name: "参数配置",
	// 	route: "",
	// 	category: Category.FOLDER.code,
	// 	icon: "fa fa-wikipedia-w",
	// 	code: MenuCode.data,
	// 	i18n: 'menu.data',
	// 	children: [{
	// 		name: "系统参数配置",
	// 		route: "/data/config",
	// 		category: Category.MENU.code,
	// 		icon: "fa fa-wordpress",
	// 		code: MenuCode.data_config,
	// 		i18n: 'menu.data_config',
	// 		children: []
	// 	}, ]
	// },
	// {
	// 	name: "设定",
	// 	route: "",
	// 	category: Category.FOLDER.code,
	// 	icon: "fa fa-gear",
	// 	code: MenuCode.setting,
	// 	i18n: 'menu.setting',
	// 	children: [{
	// 			name: "消息通知",
	// 			route: "/setting/notice",
	// 			category: Category.MENU.code,
	// 			icon: "fa fa-bell",
	// 			code: MenuCode.setting_notice,
	// 			i18n: 'menu.setting_notice',
	// 		},
	// 		// {
	// 		//     name: "弹窗消息",
	// 		//     route: "/setting/alert",
	// 		//     category: Category.MENU.code,
	// 		//     icon: "fa fa-file-sound-o",
	// 		//     code: MenuCode.setting_alert,
	// 		//     i18n: 'menu.setting_alert',
	// 		// },
	// 		{
	// 			name: "关于我们",
	// 			route: "/setting/about",
	// 			category: Category.MENU.code,
	// 			icon: "fa fa-font",
	// 			code: MenuCode.setting_about,
	// 			i18n: 'menu.setting_about',
	// 		},
	// 		// {
	// 		//     name: "免责声明",
	// 		//     route: "/setting/announce",
	// 		//     category: Category.MENU.code,
	// 		//     icon: "fa fa-vimeo",
	// 		//     code: MenuCode.setting_announce,
	// 		//     i18n: 'menu.setting_announce',
	// 		// },
	// 		{
	// 			name: "客服",
	// 			route: "/setting/customer",
	// 			category: Category.MENU.code,
	// 			icon: "fa fa-user-secret",
	// 			code: MenuCode.setting_customer,
	// 			i18n: 'menu.setting_customer',
	// 		},
	// 		{
	// 			name: "新闻",
	// 			route: "/setting/news",
	// 			category: Category.MENU.code,
	// 			icon: "fa fa-user-secret",
	// 			code: MenuCode.setting_news,
	// 			i18n: 'menu.setting_news',
	// 		},
	// 	]
	// },
	{
		name: "代理商",
		route: "",
		category: Category.FOLDER.code,
		icon: "iconfont icon-gerenziliao",
		code: MenuCode.agent,
		i18n: 'menu.agent',
		children: [{
				name: "代理商清单",
				route: "/agent/list",
				category: Category.MENU.code,
				icon: "iconfont icon-shimingrenzheng",
				code: MenuCode.agent_list,
				i18n: 'menu.agent_list',
				children: [{
						name: "添加下级",
						category: Category.BUTTON.code,
						code: 'agent_list_add'
					},
					{
						name: "删除下级",
						category: Category.BUTTON.code,
						code: 'agent_list_remove'
					},
					{
						name: "修改下级",
						category: Category.BUTTON.code,
						code: 'agent_list_update'
					},
					{
						name: "配置权限",
						category: Category.BUTTON.code,
						code: 'agent_list_auth'
					},
				]
			},
			// {
			// 	name: "代理用户清单",
			// 	route: "/agent/user",
			// 	category: Category.MENU.code,
			// 	icon: "iconfont icon-user",
			// 	code: MenuCode.agent_user,
			// 	i18n: 'menu.agent_user',
			// 	children: []
			// },
		]
	},
]

const MenuTree = {
	matchMenuTree: function(codeMap) {
		var menuList = [];
		Menus.forEach((folder) => {
			if (folder.category == Category.GROUP.code || codeMap[folder.code]) {
				var obj = Object.assign({}, folder);
				menuList.push(obj);
				obj.children = [];
				if (folder.children) {
					folder.children.forEach((menu) => {
						if (menu.category == Category.GROUP.code || codeMap[menu.code]) {
							obj.children.push(menu);
						}
					});
				}
			}
		});
		return menuList;
	},
	menuTreeWithoutGroup: function() {
		var menuList = [];
		Menus.forEach((folder) => {
			if (folder.category != Category.GROUP.code) {
				var obj = Object.assign({}, folder);
				menuList.push(obj);
				obj.children = [];
				if (folder.children) {
					folder.children.forEach((menu) => {
						if (menu.category != Category.GROUP.code) {
							var menuObj = Object.assign({}, menu);
							obj.children.push(menuObj);
							menuObj.children = [];
							if (menu.children) {
								menu.children.forEach((button) => {
									if (button.category != Category.GROUP.code) {
										menuObj.children.push(button);
									}
								});
							}
						}
					});
				}
			}
		});
		return menuList;
	},
	emptyMenuCode: function() {
		var codes = {};
		for (var i in Menus) {
			codes[Menus[i].code] = '';
			for (var j in Menus[i].children) {
				codes[Menus[i].children[j].code] = '';
				for (var k in Menus[i].children[j].children) {
					codes[Menus[i].children[j].children[k].code] = '';
				}
			}
		}
		return codes;
	}
}


export {
	Menus,
	Category,
	MenuTree
}